<template>
  <div class="new-transport">
    <div v-if="isLoad" class="row data">
      <div class="col-lg-3 col-md-6 col-12">
        <InfoBlock :transport-data="transportData" @save="save" @onInputHandler="onInputHandler" />
      </div>
      <div class="col-lg-6 col-md-12 col-12">
        <ImageSliderBlock
          :transport-data="transportData"
          @onInputHandler="onInputHandler"
          @onInputHandlerImage="onInputHandlerImage"
        />
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <CharacteristicBlock
          :transport-data="transportData"
          @onInputHandler="onInputHandler"
        />
        <DocumentBlock :transport-data="transportData" @onInputHandlerImage="onInputHandlerImage" />
      </div>
    </div>
    <div v-if="false" class="new-transport__block row">
      <div class="col-lg-6 col-md-6 col-12">
        <MaintenanceBlock />
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <WorkingTansportBlock />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    InfoBlock: () => ({
      component: import("@/components/add/transport/transport"),
    }),
    CharacteristicBlock: () => ({
      component: import("@/components/add/transport/characteristic"),
    }),
    DocumentBlock: () => ({
      component: import("@/components/add/transport/document"),
    }),
    ImageSliderBlock: () => ({
      component: import("@/components/add/transport/images"),
    }),
    MaintenanceBlock: () => ({
      component: import("@/components/add/transport/maintenance"),
    }),
    WorkingTansportBlock: () => ({
      component: import("@/components/add/transport/working"),
    }),
  },
  data() {
    return {
      transportData: {
        label: null,
        mark: null,
        model: null,
        number: null,
        body: null,
        carDate: null,
        temp: null,
        dimensions: null,
        cityId: null,
        size: null,
        sizeLength: null,
        sizeWidth: null,
        sizeHeight: null,
        capacity: null,
        volume: null,
        places: null,
        meters: null,
        weight: null,
        seats: null,
        characteristic: false,
        images: [],
        dataImgList: [],
        doc: [],
        dataDocList: [],
        wiatlon_id: null,
        tracker_id: null,
        trailer_id: null,
        trailer: null,
        registration: null,
        navigation: null,
        status: null,
        rent_from_date: null,
        rent_to_date: null,
      },
      isLoad: false,
    };
  },
  methods: {
    async save() {
      const formData = new FormData();
      formData.set("city_id", this.transportData.cityId);
      formData.set("trailer_id", this.transportData.trailer_id);
      formData.set("registration", this.transportData.registration);
      formData.set("mark_id", this.transportData.mark);
      formData.set("mark_model_id", this.transportData.model);
      formData.set("wiatlon_id", this.transportData.wiatlon_id);
      formData.set("tracker_id", this.transportData.tracker_id);
      formData.set("number", this.transportData.number);
      if (this.transportData.body) {
        formData.set("body", this.transportData.body);
      }
      formData.set("car_date", this.transportData.carDate ? this.transportData.carDate : "");
      if (this.transportData.temp) {
        formData.set("temp", this.transportData.temp);
      }
      formData.set("dimensions", this.transportData.dimensions);
      formData.set(
        "size",
        (this.transportData.sizeLength && this.transportData.sizeLength !== "undefined"
          ? this.transportData.sizeLength
          : 0) +
          "x" +
          (this.transportData.sizeWidth && this.transportData.sizeWidth !== "undefined"
            ? this.transportData.sizeWidth
            : 0) +
          "x" +
          (this.transportData.sizeHeight && this.transportData.sizeHeight !== "undefined"
            ? this.transportData.sizeHeight
            : 0)
      );
      formData.set("capacity", this.transportData.capacity || 0);
      formData.set("label", this.transportData.label || 0);
      formData.set("volume", this.transportData.volume || 0);
      formData.set("places", this.transportData.places || 0);
      formData.set("meters", this.transportData.meters || 0);
      formData.set("weight", this.transportData.weight || 0);
      formData.set("seats", this.transportData.seats || 0);
      formData.set("navigation", this.transportData.navigation);
      formData.set("status", this.transportData.status);
      formData.set("rent_from_date", this.transportData.rent_from_date);
      formData.set("rent_to_date", this.transportData.rent_to_date);
      this.transportData.doc.forEach((e, index) => {
        formData.set(`documents[${index}]`, this.transportData.doc[index]);
      });
      this.transportData.images.forEach((e, index) => {
        formData.set(`char_images[${index}]`, this.transportData.images[index]);
        formData.set(`maintenances[${index}]`, this.transportData.images[index]);
      });
      if (this.$route.query.id) formData.set("transport_id", this.$route.query.id);
      const url = this.$route.query.id ? "/web/edit-transport" : "/web/add-transport";
      await this.$api
        .post(url, formData)
        .then(() => {
          this.$toast.success("Успешно!");
          this.$router.push("/database/car-park/transports");
        })
        .catch(() => {
          this.$toast.error('Что-то пошло не так, попробуйте позднее!');
        });
    },
    onInputHandler(e) {
      this.transportData[e.key] = e.params;
    },
    onInputHandlerImage(e) {
      this.transportData[e.key] = [];
      e.params.forEach((el, i, arr) => {
        this.transportData[e.key][i] = arr[i];
      });
    },
  },
  computed: {
    ...mapState(["addTransport", "transport"]),
  },
  async created() {
    if (this.$route.query.id) {
      await this.$store.dispatch("getTransport", this.$route.query.id);
      this.transportData.cityId = this.transport?.data?.city_id?.id;
      this.transportData.size = this.transport?.data?.characteristics[0]?.size;
      this.transportData.sizeLength = this.transport?.data?.characteristics[0]?.size?.split("x")[0];
      this.transportData.sizeWidth = this.transport?.data?.characteristics[0]?.size?.split("x")[1];
      this.transportData.sizeHeight = this.transport?.data?.characteristics[0]?.size?.split("x")[2];
      this.transportData.capacity = this.transport?.data?.characteristics[0]?.capacity;
      this.transportData.volume = this.transport?.data?.characteristics[0]?.volume;
      this.transportData.places = this.transport?.data?.characteristics[0]?.places;
      this.transportData.meters = this.transport?.data?.characteristics[0]?.meters;
      this.transportData.weight = this.transport?.data?.characteristics[0]?.weight;
      this.transportData.seats = this.transport?.data?.characteristics[0]?.seats;
      this.transportData.mark = this.transport?.data?.model?.mark_id;
      this.transportData.model = this.transport?.data?.model?.id;
      this.transportData.number = this.transport?.data?.number;
      this.transportData.body = this.transport?.data?.body;
      this.transportData.carDate = this.transport?.data?.car_date?.slice(0, 4) + "-00-00";
      this.transportData.temp = this.transport?.data?.characteristics[0]?.temp;
      this.transportData.dimensions = this.transport?.data?.dimensions;
      this.transportData.dataImgList = this.transport?.data?.images;
      this.transportData.dataDocList = this.transport?.data?.documents;
      this.transportData.label = this.transport?.data?.label;
      this.transportData.wiatlon_id = this.transport?.data?.wiatlon_id;
      this.transportData.tracker_id = this.transport?.data?.tracker_id;
      this.transportData.trailer_id = this.transport?.data?.trailer_id;
      this.transportData.trailer = this.transport?.data?.trailer;
      this.transportData.registration = this.transport?.data?.registration;
      this.transportData.navigation = this.transport?.data?.navigation;
      this.transportData.status = this.transport?.data?.status;
      this.transportData.rent_from_date = this.transport?.data?.rent_from_date;
      this.transportData.rent_to_date = this.transport?.data?.rent_to_date;
    }
    this.isLoad = true;
  },
};
</script>

<style scoped>
.new-transport__block {
  margin-top: 20px;
}
.data{ 
  background: #FFFFFF;
  border-radius: 20px;
}
</style>
